import styled from 'styled-components';

export const TechStackCard = styled.div`
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;
    padding: 0.3rem 1rem;
    border-radius: 5px;

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
`

export const ProjectImageContainer = styled.div`
    display:flex;
    justify-content: ${({ justify }) => justify};
`

// width at 80% for websites, don't specify for square logos
export const ProjectImage = styled.img`
    border: ${({ solidBorder }) => (solidBorder ? '1px solid #fff' : '1px solid transparent')};
    width: ${({ width }) => width};
    height: 300px;
    object-fit: contain;
    border-radius: 10px;

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 100%;
        margin-top: 2rem;
    }
`